import { defineStore, storeToRefs } from 'pinia'
import { computed, ref } from 'vue'
import type { FactBoxItemProps } from '~/components/reports/FactBox/v2/definitions'
import useLithium from '~/composables/http/useLithium'
import { useGeneralStore } from '~/stores/general'
import { FactboxTexts, supplyFactboxesCommonSettings } from '~/constants/general'
import { getKeyByStatus, statusTooltipContent } from '~/utils/factbox/statusesTooltipContent'
import { formatObjectValueFactbox, formatOreTypeFactbox } from '~/utils/formatFactboxData'
import { orderOwnership } from '~/utils/lithium/supply/orderOwnership'

export const useLithiumSupplyMinedMineStore = defineStore('lithium-supply-mined-mine', () => {
  const generalStore = useGeneralStore()
  const { weightedUnweighted } = storeToRefs(generalStore)

  const { getMinedMineOverview, getMinedMineDetail, getMinedMineHeaders } = useLithium()

  const mineDetail = ref<{
    data?: any
    headers?: any
    factbox?: FactBoxItemProps[]
    facts?: any
    breadcrumb?: any
    factboxV2?: boolean
  }>({
    data: null,
    headers: null,
    factbox: null,
    facts: null,
    breadcrumb: null,
    factboxV2: null,
  })

  const overview = ref({
    country: {
      sourceData: null,
      data: null,
    },
    'ore-type': {
      sourceData: null,
      data: null,
    },
    region: {
      sourceData: null,
      data: null,
    },
  })

  const overviewCountry = computed(() => overview.value.country)
  const overviewOreType = computed(() => overview.value['ore-type'])
  const overviewRegion = computed(() => overview.value.region)

  const clearState = () => {
    overview.value = {
      country: {
        sourceData: null,
        data: null,
      },
      'ore-type': {
        sourceData: null,
        data: null,
      },
      region: {
        sourceData: null,
        data: null,
      },
    }
    mineDetail.value = {
      data: null,
      headers: null,
      factbox: null,
      facts: null,
      breadcrumb: null,
      factboxV2: true,
    }
  }

  const getMineOverviewData = async (type: string) => {
    const oldData = overview.value[type].data

    if (oldData) return

    const { data, $metadata } = await getMinedMineOverview({
      groupBy: type,
      valueType: weightedUnweighted.value,
    })
    overview.value[type] = {
      data,
      sourceData: $metadata.sourceData,
    }
  }

  const getMineDetailData = async (id: string) => {
    mineDetail.value.data = await getMinedMineDetail(id, weightedUnweighted.value)
  }

  const getMineHeadersData = async (id: string) => {
    const response = await getMinedMineHeaders(id, weightedUnweighted.value)
    mineDetail.value.headers = response.header
    mineDetail.value.facts = response.facts
    mineDetail.value.breadcrumb = response.breadcrumb
    mineDetail.value.breadcrumb.prevItems = [
      'Lithium',
      'Supply',
      'Mined',
      'Mine',
      response.header.title,
    ]

    const { owners, statusWithLaunchYear, operator, oreTypes, currentProduction } = response.facts

    mineDetail.value.factbox = [
      {
        ...supplyFactboxesCommonSettings,
        label: FactboxTexts.status.label,
        value: statusWithLaunchYear,
        showTooltip: Boolean(getKeyByStatus(statusWithLaunchYear)),
        tooltipId: 'tooltip-status',
        tooltipContent: statusTooltipContent(statusWithLaunchYear),
        dataTestid: FactboxTexts.status.dataTestAttribute,
      },
      {
        ...supplyFactboxesCommonSettings,
        label: FactboxTexts.operator.label,
        value: operator,
        dataTestid: FactboxTexts.operator.dataTestAttribute,
      },
      {
        ...supplyFactboxesCommonSettings,
        label: FactboxTexts.ownership.label,
        value: orderOwnership(owners),
        dataTestid: FactboxTexts.ownership.dataTestAttribute,
      },
      formatOreTypeFactbox(oreTypes),
      formatObjectValueFactbox(currentProduction),
    ]
  }

  return {
    overviewCountry,
    overviewOreType,
    overviewRegion,
    mineDetail,
    getMineOverviewData,
    getMineDetailData,
    getMineHeadersData,
    clearState,
  }
})
