import { defineStore, storeToRefs } from 'pinia'
import { ref, computed } from 'vue'
import type { FactBoxItemProps } from '~/components/reports/FactBox/v2/definitions'
import useLithium from '~/composables/http/useLithium'
import { FactboxTexts, supplyFactboxesCommonSettings } from '~/constants/general'
import type { ChemicalOverview } from '~/types/domain/chemicalOverview'
import type { OverviewItemDetail } from '~/types/domain/overviewItemDetail'
import { objectHasData } from '~/utils'
import { useGeneralStore } from '~/stores/general'

export const useLithiumSupplyChemicalOverviewStore = defineStore(
  'lithium-supply-chemical-overview',
  () => {
    const generalStore = useGeneralStore()
    const { weightedUnweighted } = storeToRefs(generalStore)

    const { getSupplyChemicalOverview } = useLithium()
    const supply = ref<ChemicalOverview>({
      operatingPlants: null,
      plannedProjects: null,
      currentProduction: null,
      currentDemand: null,
      nameplateCapacity: null,
    })

    const chemicalOverviewFactbox = (
      operatingPlants: number,
      plannedProjects: number,
      currentProduction: OverviewItemDetail,
      currentDemand: OverviewItemDetail,
    ) => {
      const factboxes: FactBoxItemProps[] = [
        {
          ...supplyFactboxesCommonSettings,
          label: FactboxTexts.operatingPlants.label,
          value: operatingPlants,
          dataTestid: FactboxTexts.operatingPlants.dataTestAttribute,
        },
        {
          ...supplyFactboxesCommonSettings,
          label: FactboxTexts.plannedProjects.label,
          value: plannedProjects,
          dataTestid: FactboxTexts.plannedProjects.dataTestAttribute,
        },
      ]

      if (currentProduction) {
        factboxes.push(formatObjectValueFactbox(currentProduction))
      }
      if (currentDemand) {
        factboxes.push(formatObjectValueFactbox(currentDemand, FactboxTexts.currentDemand))
      }

      return factboxes
    }

    const factboxOverviewPlant = computed(() => {
      const { operatingPlants, plannedProjects, currentProduction, currentDemand } = supply.value
      return chemicalOverviewFactbox(
        operatingPlants,
        plannedProjects,
        currentProduction,
        currentDemand,
      )
    })

    const isEmptyFactbox = () => {
      const { operatingPlants, plannedProjects, currentProduction, currentDemand } = supply.value
      return !operatingPlants && !plannedProjects && !currentProduction && !currentDemand
    }

    const factboxOverviewCompany = computed(() => {
      if (isEmptyFactbox()) return null
      const { operatingPlants, plannedProjects, currentProduction, currentDemand } = supply.value
      return chemicalOverviewFactbox(
        operatingPlants,
        plannedProjects,
        currentProduction,
        currentDemand,
      )
    })

    const factboxOverviewCountry = computed(() => {
      if (isEmptyFactbox()) return null
      const { operatingPlants, plannedProjects, currentProduction, currentDemand } = supply.value
      return chemicalOverviewFactbox(
        operatingPlants,
        plannedProjects,
        currentProduction,
        currentDemand,
      )
    })

    const factboxOverviewRegion = computed(() => {
      if (isEmptyFactbox()) return null
      const { operatingPlants, plannedProjects, currentProduction, currentDemand } = supply.value
      return chemicalOverviewFactbox(
        operatingPlants,
        plannedProjects,
        currentProduction,
        currentDemand,
      )
    })

    const factboxOverviewFeedstock = computed(() => {
      if (isEmptyFactbox()) return null
      const { operatingPlants, plannedProjects, currentProduction, currentDemand } = supply.value
      return chemicalOverviewFactbox(
        operatingPlants,
        plannedProjects,
        currentProduction,
        currentDemand,
      )
    })

    const factboxOverviewProduct = computed(() => {
      if (isEmptyFactbox()) return null
      const { operatingPlants, plannedProjects, currentProduction, currentDemand } = supply.value
      return chemicalOverviewFactbox(
        operatingPlants,
        plannedProjects,
        currentProduction,
        currentDemand,
      )
    })

    const clearState = () => {
      supply.value = {
        operatingPlants: null,
        plannedProjects: null,
        currentProduction: null,
        currentDemand: null,
        nameplateCapacity: null,
      }
    }

    const getOverviewData = async () => {
      const oldData = supply.value
      const hasData = objectHasData(oldData)

      if (hasData) return

      const data = await getSupplyChemicalOverview(weightedUnweighted.value)
      supply.value = data
    }

    return {
      factboxOverviewPlant,
      factboxOverviewCompany,
      factboxOverviewCountry,
      factboxOverviewRegion,
      factboxOverviewFeedstock,
      factboxOverviewProduct,
      getOverviewData,
      clearState,
    }
  },
)
