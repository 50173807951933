import { Owner } from '~/services/http/lithium/supply/chemical'

export const orderOwnership = (owners: Owner[]) =>
  owners
    .toSorted((x, y) => x.order - y.order)
    .map((owner) => `${owner.name} ${owner.participation.value}${owner.participation.unit}`)
    .reduce((prev, current) => {
      if (!current) {
        return prev
      }
      return `${prev}, ${current}`
    })
