import { defineStore, storeToRefs } from 'pinia'
import { ref, computed } from 'vue'
import useLithium from '~/composables/http/useLithium'
import { existProperty } from '~/utils/object.utils'
import { addExcludingChinaToAsia } from '~/utils'
import { useGeneralStore } from '~/stores/general'
import { FactboxTexts, supplyFactboxesCommonSettings } from '~/constants/general'
import {
  FactBoxItemTypes,
  type FactBoxItemProps,
} from '~/components/reports/FactBox/v2/definitions'
import { formatObjectValueFactbox, formatOreTypeFactbox } from '~/utils/formatFactboxData'

export const useLithiumSupplyMinedRegionStore = defineStore('lithium-supply-mined-region', () => {
  const generalStore = useGeneralStore()
  const { weightedUnweighted } = storeToRefs(generalStore)

  const { getMinedRegionsTopProducers, getMinedRegionDetail, getMinedRegionHeaders } = useLithium()
  const overview = ref()

  const regionDetail = ref<{
    country: any
    operator: any
    'ore-type': any
    status: any
    factbox: FactBoxItemProps[]
    breadcrumb: any
  }>({
    country: null,
    operator: null,
    'ore-type': null,
    status: null,
    factbox: null,
    breadcrumb: null,
  })
  const headers = ref()
  const hasWeightProperty = ref()
  const isWeight = ref()
  const title = ref()

  const company = computed(() => regionDetail.value.operator)
  const country = computed(() => regionDetail.value.country)
  const oreType = computed(() => regionDetail.value['ore-type'])
  const statusYear = computed(() => regionDetail.value?.status?.data.year)
  const status = computed(() => ({
    ...regionDetail.value.status,
    data: formatMinedStatus(regionDetail.value.status?.data.status),
  }))

  const clearState = () => {
    overview.value = null
    regionDetail.value = {
      country: null,
      operator: null,
      'ore-type': null,
      status: null,
      factbox: null,
      breadcrumb: null,
    }
    headers.value = null
  }

  const getTopProducers = async (path: string) => {
    const oldData = overview.value

    if (oldData) return
    overview.value = await getMinedRegionsTopProducers(path, weightedUnweighted.value)
  }

  const getRegionDetailData = async ({ id, type }) => {
    if (type === 'company') type = 'operator'
    const response = await getMinedRegionDetail({ id, type, valueType: weightedUnweighted.value })
    regionDetail.value[type] = response
  }

  const getRegionHeadersData = async (id) => {
    const response = await getMinedRegionHeaders(id, weightedUnweighted.value)
    headers.value = response.header
    regionDetail.value.breadcrumb = {
      prevItems: [
        'Lithium',
        'Supply',
        'Mined',
        'Region',
        addExcludingChinaToAsia(response.header.title),
      ],
    }

    const { totalOperating, totalPlanned, currentProduction, oreTypes } = response.facts
    regionDetail.value.factbox = [
      {
        ...formatOreTypeFactbox(oreTypes),
        value: oreTypes,
        variant: FactBoxItemTypes.LIST,
      },
      {
        ...supplyFactboxesCommonSettings,
        label: FactboxTexts.totalOperatingMines.label,
        value: totalOperating.toString(),
        dataTestid: FactboxTexts.totalOperatingMines.dataTestAttribute,
      },
      {
        ...supplyFactboxesCommonSettings,
        label: FactboxTexts.totalPlannedProjects.label,
        value: totalPlanned.toString(),
        dataTestid: FactboxTexts.totalPlannedProjects.dataTestAttribute,
      },
      formatObjectValueFactbox(currentProduction),
    ]

    hasWeightProperty.value = existProperty(response.facts.currentProduction, 'weighted')
    isWeight.value = response.facts.currentProduction.weighted
    title.value = response.header.title
  }

  return {
    overview,
    company,
    country,
    oreType,
    status,
    statusYear,
    hasWeightProperty,
    isWeight,
    title,
    regionDetail,
    getTopProducers,
    getRegionDetailData,
    getRegionHeadersData,
    clearState,
  }
})
