import { defineStore, storeToRefs } from 'pinia'
import { ref, computed } from 'vue'
import type { FactBoxItemProps } from '~/components/reports/FactBox/v2/definitions'
import useLithium from '~/composables/http/useLithium'
import { useGeneralStore } from '~/stores/general'
import { FactboxTexts, supplyFactboxesCommonSettings } from '~/constants/general'
import { formatObjectValueFactbox, formatOreTypeFactbox } from '~/utils/formatFactboxData'

export const useLithiumSupplyMinedCompanyStore = defineStore('lithium-supply-mined-company', () => {
  const generalStore = useGeneralStore()
  const { weightedUnweighted } = storeToRefs(generalStore)

  const { getMinedCompanyDetail, getMinedCompanyHeaders, getMinedCompaniesTopProducers } =
    useLithium()

  const overview = ref()
  const home = ref()
  const companyDetail = ref<{
    mine: any
    'ore-type': any
    country: any
    status: any
    factbox: FactBoxItemProps[]
    breadcrumb: any
  }>({
    mine: null,
    'ore-type': null,
    country: null,
    status: null,
    factbox: null,
    breadcrumb: null,
  })

  const headers = ref()
  const mine = computed(() => companyDetail.value.mine)
  const oreType = computed(() => companyDetail.value['ore-type'])
  const country = computed(() => companyDetail.value.country)
  const hasWeightProperty = computed(() => headers.value?.facts.currentProduction)
  const isWeight = computed(() => headers.value?.facts.currentProduction.weighted)
  const title = computed(() => headers.value?.header.title)
  const status = computed(() => ({
    ...companyDetail.value.status,
    data: formatMinedStatus(companyDetail.value?.status?.data.status),
  }))
  const statusYear = computed(() => companyDetail.value?.status?.data.year)

  const clearState = () => {
    overview.value = null
    headers.value = null
    companyDetail.value = {
      mine: null,
      'ore-type': null,
      country: null,
      status: null,
      factbox: null,
      breadcrumb: null,
    }
  }

  const getCompanyDetailData = async ({ id, type }) => {
    const response = await getMinedCompanyDetail({ id, type, valueType: weightedUnweighted.value })
    companyDetail.value[type] = response
  }

  const getCompanyHeadersData = async (id) => {
    const response = await getMinedCompanyHeaders(id, weightedUnweighted.value)
    headers.value = response
    companyDetail.value.breadcrumb = response.breadcrumb
    companyDetail.value.breadcrumb.prevItems = [
      'Lithium',
      'Supply',
      'Mined',
      'Companies',
      response.header.title,
    ]

    const { oreTypes, companyHQ, currentProduction, esgTier } = response.facts
    companyDetail.value.factbox = [
      {
        ...supplyFactboxesCommonSettings,
        label: FactboxTexts.marketPosition.label,
        value: response.facts.status,
        dataTestid: FactboxTexts.marketPosition.dataTestAttribute,
      },
      formatOreTypeFactbox(oreTypes),
      {
        ...supplyFactboxesCommonSettings,
        label: FactboxTexts.companyHQ.label,
        value: companyHQ,
        dataTestid: FactboxTexts.companyHQ.dataTestAttribute,
      },
      formatObjectValueFactbox(currentProduction),
      {
        ...supplyFactboxesCommonSettings,
        label: FactboxTexts.benchmarkSustainabilityIndex.label,
        value: esgTier,
        dataTestid: FactboxTexts.benchmarkSustainabilityIndex.dataTestAttribute,
      },
    ]
  }

  const getTopProducers = async (path: string) => {
    const oldData = overview.value
    if (oldData) return

    overview.value = await getMinedCompaniesTopProducers({
      path,
      valueType: weightedUnweighted.value,
    })
  }

  const fetchHomeProducers = async (limit?: number) => {
    const oldData = home.value
    if (oldData) return

    home.value = await getMinedCompaniesTopProducers({ limit, valueType: weightedUnweighted.value })
  }

  return {
    home,
    title,
    mine,
    oreType,
    country,
    status,
    statusYear,
    headers,
    hasWeightProperty,
    isWeight,
    overview,
    companyDetail,
    getCompanyDetailData,
    fetchHomeProducers,
    getCompanyHeadersData,
    getTopProducers,
    clearState,
  }
})
