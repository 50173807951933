import { Decimal } from 'decimal.js'
import toDecimalPlaces from './chartData/toDecimalPlaces'
import formatString from './formatString'
import numberWithCommas from './numberWithCommas'
import {
  FactboxTexts,
  validateIsNegativeZero,
  Units,
  getPercentageChange,
  type FactboxSetting,
  supplyFactboxesCommonSettings,
} from '~/constants/general'
import type { OverviewItemDetail } from '~/types/domain/overviewItemDetail'
import type { FactBoxItemProps } from '~/components/reports/FactBox/v2/definitions'

export const handleArray = (arr) => {
  if (!Array.isArray(arr)) return arr
  if (!arr.length) return '-'
  return arr.join(', ')
}

const styleValue = (durtyValue, units) => {
  const value = toDecimalPlaces(durtyValue, units, true) as number

  if (value > 0) {
    return `<span class="text-p-green-400 arrow-after-up" data-test="price_index_change">${value}${units}</span>`
  }

  if (value < 0) {
    return `<span class="text-s-red-300 arrow-after-down">${value}${units}</span>`
  }

  const newVal = validateIsNegativeZero(value)

  return `<span class="text-white">${newVal}${units} ~</span>`
}

const getReplaceHeaders = ({ key, replaceHeaders, factboxTextItem, customUnit, customLabel }) => {
  let columns = null
  let rows = null
  let compareStatusWithPastPrice = null
  let pastPriceValue = null
  let unit = customUnit || factboxTextItem?.units
  let label = factboxTextItem?.label ?? customLabel
  let isList = false
  const labelClass = factboxTextItem?.labelClass

  if (!Object.prototype.hasOwnProperty.call(replaceHeaders, key)) {
    return {
      isList,
      label,
      unit,
      columns,
      rows,
    }
  }

  const replaceHeader = replaceHeaders[key]

  if (replaceHeader.unit) {
    unit = replaceHeader.unit
  }

  if (replaceHeader.columns) {
    columns = replaceHeader.columns
  }

  if (replaceHeader.rows) {
    rows = replaceHeader.rows
  }

  if (replaceHeader.isList) {
    isList = replaceHeader.isList
  }

  if (factboxTextItem?.concatLabel) {
    label = formatString(factboxTextItem.label, replaceHeader.label)
  }

  if (replaceHeader.compareStatusWithPastPrice) {
    compareStatusWithPastPrice = replaceHeader.compareStatusWithPastPrice
  }

  if (replaceHeader.pastPriceValue) {
    pastPriceValue = replaceHeader.pastPriceValue
  }

  return {
    isList,
    label,
    labelClass,
    unit,
    columns,
    rows,
    compareStatusWithPastPrice,
    pastPriceValue,
  }
}

export const formatOreTypeFactbox = (value: string[]): FactBoxItemProps => ({
  value: handleArray(value),
  label: FactboxTexts.oreTypes.label,
  ...supplyFactboxesCommonSettings,
  dataTestid: FactboxTexts.oreTypes.dataTestAttribute,
})

type FormatValueParams = {
  value: number | string | string[]
  settings?: FactboxSetting
  previousValue?: number
}

export const getFormatValueV2 = ({
  value: currentValue,
  settings,
  previousValue,
}: FormatValueParams) => {
  let value = currentValue

  if (!settings) return value

  const { units } = settings

  const {
    toDecimalPlaces: convertToDecimalPlaces,
    fixedDecimalPlace,
    concatValue,
    numberWithCommas: hasNumberWithCommas,
    htmlValue,
  } = settings

  if (convertToDecimalPlaces) {
    value = toDecimalPlaces(value, units, fixedDecimalPlace)
  }

  if (hasNumberWithCommas && !units) {
    value = numberWithCommas(value)
  }

  if (concatValue) {
    value = formatString(concatValue, value, units)
  }

  if (htmlValue) {
    value = styleValue(value, units)
  }

  if (previousValue) {
    const variation = getPercentageChange(value, previousValue)
    const variationFormatted = Number(toDecimalPlaces(variation, Units.DOLLAR, true))
    const normalizedVariation =
      variationFormatted < 0 ? variationFormatted * -1 : variationFormatted
    value = `${value} (${normalizedVariation}${Units.PERCENTAGE})`
  }

  return value
}

export const formatObjectValueFactbox = (
  objectValue: OverviewItemDetail,
  setting = FactboxTexts.currentProduction,
): FactBoxItemProps => ({
  ...supplyFactboxesCommonSettings,
  label: formatString(setting.label, objectValue.year.toString()),
  value: getFormatValueV2({
    value: objectValue.value,
    settings: {
      ...setting,
      units: objectValue.unit,
    },
  }),
  isWeighted: objectValue?.weighted,
  dataTestid: setting.dataTestAttribute,
})

const getFormatValue = ({ key, originalValue, unit, factboxTextItem, isList, pastPriceValue }) => {
  let value = originalValue

  if (!factboxTextItem) return originalValue

  const {
    formatArray,
    toDecimalPlaces: convertToDecimalPlaces,
    fixedDecimalPlace,
    concatValue,
    numberWithCommas: hasNumberWithCommas,
    htmlValue,
  } = factboxTextItem

  if (formatArray && !isList) {
    value = handleArray(value)
  }

  if (key === 'pipelineCapacity') {
    const pipelineCapacity = new Decimal(value)
    value = pipelineCapacity.toDecimalPlaces(2).toNumber()
  }

  if (convertToDecimalPlaces) {
    value = toDecimalPlaces(value, unit, fixedDecimalPlace)
  }

  if (hasNumberWithCommas && !unit) {
    value = numberWithCommas(value)
  }

  if (concatValue) {
    value = formatString(concatValue, value, unit)
  }

  if (htmlValue) {
    value = styleValue(value, unit)
  }

  if (pastPriceValue) {
    const variation = getPercentageChange(originalValue, pastPriceValue)
    const variationFormatted = Number(toDecimalPlaces(variation, Units.DOLLAR, true))
    const normalizedVariation =
      variationFormatted < 0 ? variationFormatted * -1 : variationFormatted
    value = `${value} (${normalizedVariation}${Units.PERCENTAGE})`
  }

  return value
}

export default function (
  headers,
  customLabel?: ((value: any, key: string) => string) | string,
  customUnit = null,
  replaceHeaders = {},
) {
  const headersArray = Object.keys(headers)

  return headersArray.map((key) => {
    const originalValue = headers[key]
    const factboxTextItem = FactboxTexts[key]

    const customLabelNormalized =
      customLabel && typeof customLabel === 'function'
        ? customLabel(originalValue, key)
        : customLabel

    const {
      label,
      unit,
      columns,
      rows,
      isList,
      compareStatusWithPastPrice,
      pastPriceValue,
      labelClass,
    } = getReplaceHeaders({
      key,
      replaceHeaders,
      factboxTextItem,
      customUnit,
      customLabel: customLabelNormalized,
    })

    const value = getFormatValue({
      key,
      originalValue,
      unit,
      factboxTextItem,
      isList,
      pastPriceValue,
    })

    let hasOther = false
    if (factboxTextItem?.validateHasOther) {
      hasOther = originalValue.some((item) => item.toUpperCase() === 'OTHER')
    }

    return {
      label,
      labelClass,
      value,
      columns,
      rows,
      htmlValue: factboxTextItem?.htmlValue,
      dataTestAttribute: factboxTextItem?.dataTestAttribute,
      hasWeightProperty: replaceHeaders[key]?.hasWeightProperty,
      isWeight: replaceHeaders[key]?.isWeight,
      compareStatusWithPastPrice,
      hasOther,
    }
  })
}
