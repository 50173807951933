import { defineStore, storeToRefs } from 'pinia'
import { computed, ref } from 'vue'
import type { FactBoxItemProps } from '~/components/reports/FactBox/v2/definitions'
import useLithium from '~/composables/http/useLithium'
import { useGeneralStore } from '~/stores/general'
import { getFormattedChemicalCompanyFactbox } from '~/utils/factbox/lithium/supply/chemical'

export const useLithiumSupplyChemicalCompanyStore = defineStore(
  'lithium-supply-chemical-company',
  () => {
    const generalStore = useGeneralStore()
    const { weightedUnweighted } = storeToRefs(generalStore)

    const { getChemicalCompaniesTopProducers, getChemicalCompanyDetail, getChemicalCompanyHeader } =
      useLithium()
    const overview = ref()
    const home = ref()

    const headers = ref()
    const breadcrumb = ref({ prevItems: [] })
    const company = ref<{
      plant: any
      feedstock: any
      product: any
      country: any
      status: any
      factbox: FactBoxItemProps[]
      breadcrumb: any
    }>({
      plant: null,
      feedstock: null,
      product: null,
      country: null,
      status: null,
      factbox: null,
      breadcrumb: null,
    })

    const title = computed(() => headers.value?.header?.title)
    const plant = computed(() => company.value.plant)
    const feedstock = computed(() => company.value.feedstock)
    const product = computed(() => company.value.product)
    const country = computed(() => company.value.country)
    const status = computed(() => ({
      ...company.value.status,
      data: formatChemicalStatus(company.value.status?.data.status),
    }))
    const statusYear = computed(() => company.value?.status?.data.year)

    const clearState = () => {
      overview.value = null
      headers.value = null
      company.value = {
        plant: null,
        feedstock: null,
        product: null,
        country: null,
        status: null,
        factbox: null,
        breadcrumb: null,
      }
    }

    const getTopProducers = async (path: string) => {
      const oldData = overview.value
      if (oldData) return

      overview.value = await getChemicalCompaniesTopProducers({
        path,
        valueType: weightedUnweighted.value,
      })
    }

    const fetchHomeProducers = async (limit?: number) => {
      const oldData = home.value

      if (oldData) return

      home.value = await getChemicalCompaniesTopProducers({
        limit,
        valueType: weightedUnweighted.value,
      })
    }

    const getCompanyDetailData = async ({ id, type }) => {
      const newType = type.replace('feed-stock', 'feedstock')
      company.value[newType] = await getChemicalCompanyDetail({
        id,
        type,
        valueType: weightedUnweighted.value,
      })
    }

    const getCompanyHeadersData = async (id: string) => {
      const response = await getChemicalCompanyHeader(id, weightedUnweighted.value)
      headers.value = response
      company.value.factbox = getFormattedChemicalCompanyFactbox(response.facts)
      company.value.breadcrumb = response.breadcrumb
      company.value.breadcrumb.prevItems = [
        'Lithium',
        'Supply',
        'Chemical',
        'Company',
        response.header.title,
      ]
    }

    return {
      home,
      overview,
      plant,
      feedstock,
      breadcrumb,
      product,
      country,
      status,
      statusYear,
      title,
      company,
      getCompanyDetailData,
      getCompanyHeadersData,
      getTopProducers,
      fetchHomeProducers,
      clearState,
    }
  },
)
