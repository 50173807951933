import { defineStore, storeToRefs } from 'pinia'
import { ref, computed } from 'vue'
import useLithium from '~/composables/http/useLithium'
import { getFormattedChemicalRegionFactbox } from '~/utils/factbox/lithium/supply/chemical'
import { addExcludingChinaToAsia } from '~/utils'
import { useGeneralStore } from '~/stores/general'
import type { FactBoxItemProps } from '~/components/reports/FactBox/v2/definitions'

export const useLithiumSupplyChemicalRegionStore = defineStore(
  'lithium-supply-chemical-region',
  () => {
    const generalStore = useGeneralStore()
    const { weightedUnweighted } = storeToRefs(generalStore)

    const { getChemicalRegionsTopProducers, getChemicalRegionDetail, getChemicalRegionHeaders } =
      useLithium()

    const overview = ref()
    const headers = ref()
    const breadcrumb = ref({ prevItems: [] })
    const factbox = ref()
    const region = ref<{
      country: any
      operator: any
      feedstock: any
      product: any
      status: any
      factbox: FactBoxItemProps[]
      breadcrumb: any
    }>({
      country: null,
      operator: null,
      feedstock: null,
      product: null,
      status: null,
      factbox: null,
      breadcrumb: null,
    })

    const title = computed(() => headers.value?.header?.title)
    const country = computed(() => region.value.country)
    const company = computed(() => region.value.operator)
    const feedstock = computed(() => region.value.feedstock)
    const product = computed(() => region.value.product)
    const status = computed(() => ({
      ...region.value.status,
      data: formatChemicalStatus(region.value.status?.data.status),
    }))
    const statusYear = computed(() => region.value?.status?.data.year)

    const clearState = () => {
      overview.value = null
      headers.value = null
      factbox.value = null
      region.value = {
        country: null,
        operator: null,
        feedstock: null,
        product: null,
        status: null,
        factbox: null,
        breadcrumb: null,
      }
    }

    const getTopProducers = async (path: string) => {
      const oldData = overview.value
      if (oldData) return
      overview.value = await getChemicalRegionsTopProducers(path, weightedUnweighted.value)
    }

    const getRegionDetailData = async ({ id, type }) => {
      if (type === 'company') type = 'operator'
      const response = await getChemicalRegionDetail({
        id,
        type,
        valueType: weightedUnweighted.value,
      })
      const newType = type.replace('feed-stock', 'feedstock')
      region.value[newType] = response
    }

    const getRegionHeadersData = async (id) => {
      const response = await getChemicalRegionHeaders(id, weightedUnweighted.value)
      headers.value = response
      region.value.factbox = getFormattedChemicalRegionFactbox(response.facts)
      region.value.breadcrumb = {
        prevItems: [
          'Lithium',
          'Supply',
          'Chemical',
          'Region',
          addExcludingChinaToAsia(response.header.title),
        ],
      }
    }

    return {
      title,
      country,
      company,
      feedstock,
      product,
      status,
      statusYear,
      breadcrumb,
      factbox,
      overview,
      region,
      getTopProducers,
      getRegionDetailData,
      getRegionHeadersData,
      clearState,
    }
  },
)
