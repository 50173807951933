import { defineStore, storeToRefs } from 'pinia'
import { ref, computed } from 'vue'
import type { FactBoxItemProps } from '~/components/reports/FactBox/v2/definitions'
import useLithium from '~/composables/http/useLithium'
import {
  formatRegionsBreadCrumbs,
  FactboxTexts,
  supplyFactboxesCommonSettings,
} from '~/constants/general'
import { useGeneralStore } from '~/stores/general'
import { formatObjectValueFactbox, formatOreTypeFactbox } from '~/utils/formatFactboxData'
import { existProperty } from '~/utils/object.utils'

export const useLithiumSupplyMinedCountryStore = defineStore('lithium-supply-mined-country', () => {
  const generalStore = useGeneralStore()
  const { weightedUnweighted } = storeToRefs(generalStore)

  const { getMinedCountriesTopProducers, getMinedCountryDetail, getMinedCountryHeaders } =
    useLithium()
  const overview = ref()
  const home = ref()
  const countryDetail = ref<{
    operator: any
    'ore-type': any
    status: any
    factbox: FactBoxItemProps[]
    breadcrumb: any
  }>({
    operator: null,
    'ore-type': null,
    status: null,
    factbox: null,
    breadcrumb: null,
  })
  const header = ref()

  const oreType = computed(() => countryDetail.value['ore-type'])
  const company = computed(() => countryDetail.value.operator)
  const title = computed(() => header.value?.header.title)
  const status = computed(() => ({
    ...countryDetail.value.status,
    data: formatMinedStatus(countryDetail.value.status?.data.status),
  }))
  const statusYear = computed(() => countryDetail.value?.status?.data.year)

  const hasWeightProperty = computed(() =>
    existProperty(header.value?.facts.currentProduction, 'weighted'),
  )
  const isWeight = computed(() => header.value?.facts.currentProduction?.weighted)

  const breadcrumb = computed(() => {
    if (!header.value?.breadcrumb) return

    return {
      prevItems: ['Lithium', 'Supply', 'Mined', 'Country', header.value.header.title],
      regions: formatRegionsBreadCrumbs(header.value.breadcrumb.regions[0]),
    }
  })

  const clearState = () => {
    overview.value = null
    home.value = null
    countryDetail.value = {
      operator: null,
      'ore-type': null,
      status: null,
      factbox: null,
      breadcrumb: null,
    }
    header.value = null
  }

  const getTopProducers = async (path: string) => {
    const oldData = overview.value

    if (oldData) return
    overview.value = await getMinedCountriesTopProducers({
      path,
      valueType: weightedUnweighted.value,
    })
  }

  const fetchHomeProducers = async (limit?: number) => {
    const oldData = home.value

    if (oldData) return

    home.value = await getMinedCountriesTopProducers({ limit, valueType: weightedUnweighted.value })
  }

  const getCountryDetailData = async ({ id, type }) => {
    if (type === 'company') type = 'operator'
    const response = await getMinedCountryDetail({ id, type, valueType: weightedUnweighted.value })
    countryDetail.value[type] = response
  }

  const getCountryHeadersData = async (id) => {
    const response = await getMinedCountryHeaders(id, weightedUnweighted.value)
    header.value = response
    countryDetail.value.breadcrumb = response.breadcrumb
    countryDetail.value.breadcrumb.prevItems = [
      'Lithium',
      'Supply',
      'Mined',
      'Country',
      response.header.title,
    ]

    const { oreTypes, currentProduction, totalOperating, totalPlanned } = response.facts
    countryDetail.value.factbox = [
      formatOreTypeFactbox(oreTypes),
      {
        ...supplyFactboxesCommonSettings,
        label: FactboxTexts.totalOperatingMines.label,
        value: totalOperating.toString(),
        dataTestid: FactboxTexts.totalOperatingMines.dataTestAttribute,
      },
      {
        ...supplyFactboxesCommonSettings,
        label: FactboxTexts.totalPlannedProjects.label,
        value: totalPlanned.toString(),
        dataTestid: FactboxTexts.totalPlannedProjects.dataTestAttribute,
      },
      formatObjectValueFactbox(currentProduction),
    ]
  }

  return {
    title,
    breadcrumb,
    oreType,
    company,
    status,
    statusYear,
    overview,
    hasWeightProperty,
    isWeight,
    home,
    countryDetail,
    fetchHomeProducers,
    getTopProducers,
    getCountryDetailData,
    getCountryHeadersData,
    clearState,
  }
})
