const Statuses = {
  closed: {
    value: 'Closed',
    text: 'Closed',
    content: 'asset which is not producing.',
  },
  operating: {
    value: 'Operating',
    text: 'Operating',
    content: 'are those actively producing material today.',
  },
  possible: {
    value: 'Possible',
    text: 'Possible',
    content:
      'projects are in the earlier stages of development with only a small portion of financing secured. Note with heterogeneous nature of the industry, not all projects will fit neatly into these categories, where this occurs, discretion is applied.',
  },
  highlyProbable: {
    value: 'Highly Probable',
    text: 'Highly Probable Projects',
    content:
      'have completed necessary public market requirements and government approvals, are fully funded, and are expected to place their product in the market in the next 24 months.',
  },
  probable: {
    value: 'Probable',
    text: 'Probable',
    content:
      'projects are those that have secured a significant proportion of its funding, and completed certain feasibility milestones that would support production within the next 5 years.',
  },
  careAndMaintenance: {
    value: 'Care and Maintenance',
    text: 'Care and Maintenance / Idle',
    content: 'were at some point in production but have been idled/placed on care and maintenance.',
  },
  brownfieldExpansion: {
    value: 'Brownfield Expansion',
    text: 'Brownfield expansions',
    content: 'are expansions associated with operating projects.',
  },
}

const getKeyByStatus = (status: string) => {
  const key = Object.keys(Statuses).find((key) => status.includes(Statuses[key].value))
  return key
}

const statusTooltipContent = (status: string) => {
  const selectedStatusKey = getKeyByStatus(status)

  if (!selectedStatusKey) return null

  const selectedStatus = Statuses[selectedStatusKey]

  if (selectedStatusKey === 'closed') {
    return `<div class="text-white text-xs font-normal">
      A <span class="font-bold">${selectedStatus.text}</span> ${selectedStatus.content}
    </div>`
  }

  return `<div class="text-white text-xs font-normal">
    <span class="font-bold">${selectedStatus.text}</span> ${selectedStatus.content}
  </div>`
}

export { statusTooltipContent, getKeyByStatus }
