import { defineStore, storeToRefs } from 'pinia'
import { ref, computed } from 'vue'
import type { FactBoxItemProps } from '~/components/reports/FactBox/v2/definitions'
import useLithium from '~/composables/http/useLithium'
import { useGeneralStore } from '~/stores/general'
import { FactboxTexts, supplyFactboxesCommonSettings } from '~/constants/general'
import { formatObjectValueFactbox, handleArray } from '~/utils/formatFactboxData'

export const useLithiumSupplyMinedOreTypeStore = defineStore(
  'lithium-supply-mined-ore-type',
  () => {
    const generalStore = useGeneralStore()
    const { weightedUnweighted } = storeToRefs(generalStore)

    const { getMinedOreTypeTopProducers, getMineOreTypeHeaders, getMineOreTypeDetail } =
      useLithium()

    const overview = ref()
    const oreTypeDetail = ref<{
      mine: any
      company: any
      country: any
      status: any
      region: any
      factbox: FactBoxItemProps[]
      breadcrumb: any
    }>({
      mine: null,
      company: null,
      country: null,
      status: null,
      region: null,
      factbox: null,
      breadcrumb: null,
    })

    const headers = ref()

    const mine = computed(() => oreTypeDetail.value.mine)
    const company = computed(() => oreTypeDetail.value.company)
    const country = computed(() => oreTypeDetail.value.country)
    const region = computed(() => oreTypeDetail.value.region)
    const title = computed(() => headers.value?.header.title)
    const hasWeightProperty = computed(() => headers.value?.facts.currentProduction)
    const isWeight = computed(() => headers.value?.facts.currentProduction.weighted)
    const status = computed(() => ({
      ...oreTypeDetail.value.status,
      data: formatMinedStatus(oreTypeDetail.value.status?.data.status),
    }))
    const statusYear = computed(() => oreTypeDetail.value?.status?.data.year)

    const clearState = () => {
      overview.value = null
      oreTypeDetail.value = {
        mine: null,
        company: null,
        country: null,
        status: null,
        region: null,
        factbox: null,
        breadcrumb: null,
      }
      headers.value = null
    }

    const getTopProducers = async (path: string) => {
      const oldData = overview.value
      if (oldData) return
      overview.value = await getMinedOreTypeTopProducers(path, weightedUnweighted.value)
    }

    const getOreTypeHeadersData = async (id) => {
      const response = await getMineOreTypeHeaders(id, weightedUnweighted.value)
      headers.value = response
      oreTypeDetail.value.breadcrumb = {
        prevItems: ['Lithium', 'Supply', 'Mined', 'Ore Type', response.header.title],
      }

      const { regionsProduction, operatingMines, plannedProjects, currentProduction } =
        response.facts
      oreTypeDetail.value.factbox = [
        {
          ...supplyFactboxesCommonSettings,
          label: FactboxTexts.regionsProduction.label,
          value: handleArray(regionsProduction),
          dataTestid: FactboxTexts.regionsProduction.dataTestAttribute,
        },
        formatObjectValueFactbox(currentProduction),
        {
          ...supplyFactboxesCommonSettings,
          label: FactboxTexts.operatingMines.label,
          value: `${operatingMines}`,
          dataTestid: FactboxTexts.operatingMines.dataTestAttribute,
        },
        {
          ...supplyFactboxesCommonSettings,
          label: FactboxTexts.plannedProjects.label,
          value: `${plannedProjects}`,
          dataTestid: FactboxTexts.plannedProjects.dataTestAttribute,
        },
      ]
    }

    const getOreTypeDetailData = async ({ id, type }) => {
      const response = await getMineOreTypeDetail({ id, type, valueType: weightedUnweighted.value })
      oreTypeDetail.value[type] = response
    }

    return {
      overview,
      mine,
      company,
      country,
      region,
      status,
      statusYear,
      headers,
      title,
      hasWeightProperty,
      isWeight,
      oreTypeDetail,
      getTopProducers,
      getOreTypeHeadersData,
      getOreTypeDetailData,
      clearState,
    }
  },
)
