export const formatMinedStatus = (minedStatus) => {
  if (!minedStatus) return []
  return minedStatus.map((status) => ({
    ...status,
    data: status.mines,
  }))
}

export const formatChemicalStatus = (chemicalStatus) => {
  return chemicalStatus.map((status) => ({
    ...status,
    data: status.plants,
  }))
}
