import { defineStore, storeToRefs } from 'pinia'
import { ref, computed } from 'vue'
import type { FactBoxItemProps } from '~/components/reports/FactBox/v2/definitions'
import useLithium from '~/composables/http/useLithium'
import { formatRegionsBreadCrumbs } from '~/constants/general'
import { useGeneralStore } from '~/stores/general'
import { getFormattedChemicalCountryFactbox } from '~/utils/factbox/lithium/supply/chemical'

export const useLithiumSupplyChemicalCountryStore = defineStore(
  'lithium-supply-chemical-country',
  () => {
    const generalStore = useGeneralStore()
    const { weightedUnweighted } = storeToRefs(generalStore)

    const {
      getChemicalCountriesTopProducers,
      getChemicalCountryDetail,
      getChemicalCountryHeaders,
    } = useLithium()
    const overview = ref()
    const home = ref()
    const headers = ref()
    const breadcrumb = ref({ prevItems: [], regions: [] })
    const factbox = ref()
    const country = ref<{
      operator: any
      feedstock: any
      product: any
      status: any
      factbox: FactBoxItemProps[]
      breadcrumb: any
    }>({
      operator: null,
      feedstock: null,
      product: null,
      status: null,
      factbox: null,
      breadcrumb: null,
    })

    const title = computed(() => headers.value?.header?.title)
    const company = computed(() => country.value.operator)
    const feedstock = computed(() => country.value.feedstock)
    const product = computed(() => country.value.product)
    const status = computed(() => ({
      ...country.value.status,
      data: formatChemicalStatus(country.value.status?.data?.status),
    }))
    const statusYear = computed(() => country.value?.status?.data.year)

    const clearState = () => {
      overview.value = null
      home.value = null
      headers.value = null
      factbox.value = null
      country.value = {
        operator: null,
        feedstock: null,
        product: null,
        status: null,
        factbox: null,
        breadcrumb: null,
      }
    }

    const getTopProducers = async (path: string) => {
      const oldData = overview.value
      if (oldData) return
      overview.value = await getChemicalCountriesTopProducers({
        path,
        valueType: weightedUnweighted.value,
      })
    }

    const fetchHomeProducers = async (limit?: number) => {
      const oldData = home.value
      if (oldData) return
      home.value = await getChemicalCountriesTopProducers({
        limit,
        valueType: weightedUnweighted.value,
      })
    }

    const getCountryDetailData = async ({ id, type }) => {
      if (type === 'company') type = 'operator'
      const newType = type.replace('feed-stock', 'feedstock')
      const response = await getChemicalCountryDetail({
        id,
        type,
        valueType: weightedUnweighted.value,
      })
      country.value[newType] = response
    }

    const getCountryHeadersData = async (id) => {
      const response = await getChemicalCountryHeaders(id, weightedUnweighted.value)
      headers.value = response
      country.value.factbox = getFormattedChemicalCountryFactbox(response.facts)
      country.value.breadcrumb = {
        prevItems: ['Lithium', 'Supply', 'Chemical', 'Country', response.header.title],
        regions: formatRegionsBreadCrumbs(response.breadcrumb.regions[0]),
      }
    }

    return {
      title,
      company,
      feedstock,
      product,
      status,
      statusYear,
      factbox,
      breadcrumb,
      overview,
      home,
      country,
      getTopProducers,
      fetchHomeProducers,
      getCountryDetailData,
      getCountryHeadersData,
      clearState,
    }
  },
)
