import { defineStore, storeToRefs } from 'pinia'
import { ref, computed } from 'vue'
import useLithium from '~/composables/http/useLithium'
import { existProperty } from '~/utils/object.utils'
import { getFormattedChemicalPlantFactbox } from '~/utils/factbox/lithium/supply/chemical'
import { orderOwnership } from '~/utils/lithium/supply/orderOwnership'
import { useGeneralStore } from '~/stores/general'
import type { FactBoxItemProps } from '~/components/reports/FactBox/v2/definitions'

export const useLithiumSupplyChemicalPlantStore = defineStore(
  'lithium-supply-chemical-plant',
  () => {
    const generalStore = useGeneralStore()
    const { weightedUnweighted } = storeToRefs(generalStore)

    const { getChemicalPlantOverview, getChemicalPlantDetail, getChemicalPlantHeaders } =
      useLithium()
    const overview = ref({
      country: {
        data: null,
        sourceData: null,
      },
      feedstock: {
        data: null,
        sourceData: null,
      },
      region: {
        data: null,
        sourceData: null,
      },
      product: {
        data: null,
        sourceData: null,
      },
    })
    const headers = ref()
    const breadcrumb = ref()
    const factbox = ref()
    const facts = ref()
    const plant = ref()
    const plantDetails = ref<{
      breadcrumb: any
      factbox: FactBoxItemProps[]
    }>({
      factbox: null,
      breadcrumb: null,
    })

    const title = computed(() => headers.value?.header?.title)
    const overviewCountry = computed(() => overview.value.country)
    const overviewFeedstock = computed(() => overview.value.feedstock)
    const overviewRegion = computed(() => overview.value.region)
    const overviewProduct = computed(() => overview.value.product)

    const clearState = () => {
      overview.value = {
        country: {
          data: null,
          sourceData: null,
        },
        feedstock: {
          data: null,
          sourceData: null,
        },
        region: {
          data: null,
          sourceData: null,
        },
        product: {
          data: null,
          sourceData: null,
        },
      }
      headers.value = null
      factbox.value = null
      facts.value = null
      plant.value = null
      plantDetails.value = {
        factbox: null,
        breadcrumb: null,
      }
    }

    const getPlantOverviewData = async (type) => {
      const oldData = overview.value[type].data

      if (oldData) return

      const { data, $metadata } = await getChemicalPlantOverview({
        groupBy: type,
        valueType: weightedUnweighted.value,
      })
      overview.value[type] = {
        data,
        sourceData: $metadata.sourceData,
      }
    }

    const getPlantDetailData = async (id) => {
      const response = await getChemicalPlantDetail(id, weightedUnweighted.value)
      const hasWeightProperty = existProperty(response.data, 'weighted')
      plant.value = { ...response, hasWeightProperty }
    }

    const getPlantHeadersData = async (id) => {
      const response = await getChemicalPlantHeaders(id, weightedUnweighted.value)
      headers.value = response
      facts.value = response.facts
      plantDetails.value.breadcrumb = response.breadcrumb
      plantDetails.value.breadcrumb.prevItems = [
        'Lithium',
        'Supply',
        'Chemical',
        'Plant',
        response.header.title,
      ]

      const { owners } = response.facts
      plantDetails.value.factbox = getFormattedChemicalPlantFactbox({
        ...response.facts,
        ownership: orderOwnership(owners),
      })
    }

    return {
      plant,
      breadcrumb,
      factbox,
      title,
      overviewCountry,
      overviewFeedstock,
      overviewRegion,
      overviewProduct,
      facts,
      plantDetails,
      getPlantOverviewData,
      getPlantDetailData,
      getPlantHeadersData,
      clearState,
    }
  },
)
