import { defineStore, storeToRefs } from 'pinia'
import { ref, computed } from 'vue'
import type { FactBoxItemProps } from '~/components/reports/FactBox/v2/definitions'
import useLithium from '~/composables/http/useLithium'
import { FactboxTexts, supplyFactboxesCommonSettings } from '~/constants/general'
import type { MinedOverview } from '~/types/domain/minedOverview'
import type { OverviewItemDetail } from '~/types/domain/overviewItemDetail'
import { objectHasData } from '~/utils'
import { useGeneralStore } from '~/stores/general'
import { formatObjectValueFactbox } from '~/utils/formatFactboxData'

export const useLithiumSupplyMinedOverviewStore = defineStore(
  'lithiu-supply-mined-overview',
  () => {
    const generalStore = useGeneralStore()
    const { weightedUnweighted } = storeToRefs(generalStore)

    const { getSupplyMinedOverview } = useLithium()

    const supply = ref<MinedOverview>({
      operatingMines: null,
      plannedProjects: null,
      currentProduction: null,
      currentDemand: null,
      nameplateCapacity: null,
    })

    const isEmptyFactbox = () => {
      const { operatingMines, plannedProjects, currentProduction, currentDemand } = supply.value
      return !operatingMines && !plannedProjects && !currentProduction && !currentDemand
    }

    const minedOverviewFactbox = (
      operatingMines: number,
      plannedProjects: number,
      currentProduction: OverviewItemDetail,
      currentDemand: OverviewItemDetail,
    ) => {
      const factboxes: FactBoxItemProps[] = [
        {
          ...supplyFactboxesCommonSettings,
          label: FactboxTexts.operatingMines.label,
          value: `${operatingMines}`,
          dataTestid: FactboxTexts.operatingMines.dataTestAttribute,
        },
        {
          ...supplyFactboxesCommonSettings,
          label: FactboxTexts.plannedProjects.label,
          value: `${plannedProjects}`,
          dataTestid: FactboxTexts.plannedProjects.dataTestAttribute,
        },
      ]
      if (currentProduction) {
        factboxes.splice(2, 0, formatObjectValueFactbox(currentProduction))
      }
      if (currentDemand) {
        factboxes.push(formatObjectValueFactbox(currentDemand, FactboxTexts.currentDemand))
      }

      return factboxes
    }

    const factboxOverviewMine = computed(() => {
      if (isEmptyFactbox()) return null

      const { operatingMines, plannedProjects, currentProduction, currentDemand } = supply.value
      return minedOverviewFactbox(operatingMines, plannedProjects, currentProduction, currentDemand)
    })

    const factboxOverviewCompany = computed(() => {
      if (isEmptyFactbox()) return null
      const { operatingMines, plannedProjects, currentProduction, currentDemand } = supply.value
      return minedOverviewFactbox(operatingMines, plannedProjects, currentProduction, currentDemand)
    })

    const factboxOverviewCountry = computed(() => {
      if (isEmptyFactbox()) return null
      const { operatingMines, plannedProjects, currentProduction, currentDemand } = supply.value
      return minedOverviewFactbox(operatingMines, plannedProjects, currentProduction, currentDemand)
    })

    const factboxOverviewRegion = computed(() => {
      if (isEmptyFactbox()) return null
      const { operatingMines, plannedProjects, currentProduction, currentDemand } = supply.value
      return minedOverviewFactbox(operatingMines, plannedProjects, currentProduction, currentDemand)
    })

    const factboxOverviewOreType = computed(() => {
      if (isEmptyFactbox()) return null
      const { currentProduction, operatingMines, plannedProjects, currentDemand } = supply.value

      const factboxes: FactBoxItemProps[] = [
        {
          ...supplyFactboxesCommonSettings,
          label: FactboxTexts.operatingMines.label,
          value: operatingMines,
          dataTestid: FactboxTexts.operatingMines.dataTestAttribute,
        },
        {
          ...supplyFactboxesCommonSettings,
          label: FactboxTexts.plannedProjects.label,
          value: plannedProjects,
          dataTestid: FactboxTexts.plannedProjects.dataTestAttribute,
        },
      ]
      if (currentProduction) {
        factboxes.unshift(formatObjectValueFactbox(currentProduction))
      }
      if (currentDemand) {
        factboxes.push(formatObjectValueFactbox(currentDemand, FactboxTexts.currentDemand))
      }

      return factboxes
    })

    const getOverviewData = async () => {
      const oldData = supply.value
      const hasData = objectHasData(oldData)

      if (hasData) return

      const data = await getSupplyMinedOverview(weightedUnweighted.value)
      supply.value = data
    }

    const clearState = () => {
      supply.value = {
        operatingMines: null,
        plannedProjects: null,
        currentProduction: null,
        currentDemand: null,
        nameplateCapacity: null,
      }
    }

    return {
      factboxOverviewMine,
      factboxOverviewCompany,
      factboxOverviewCountry,
      factboxOverviewRegion,
      factboxOverviewOreType,
      getOverviewData,
      clearState,
    }
  },
)
