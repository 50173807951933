import { defineStore, storeToRefs } from 'pinia'
import { ref, computed } from 'vue'
import type { FactBoxItemProps } from '~/components/reports/FactBox/v2/definitions'
import useLithium from '~/composables/http/useLithium'
import { useGeneralStore } from '~/stores/general'
import { getFormattedChemicalProductFactbox } from '~/utils/factbox/lithium/supply/chemical'

export const useLithiumSupplyChemicalFeedstockStore = defineStore(
  'lithium-supply-chemical-feedstock',
  () => {
    const generalStore = useGeneralStore()
    const { weightedUnweighted } = storeToRefs(generalStore)

    const {
      getChemicalFeedstockProducers,
      getChemicalFeedstockDetail,
      getChemicalFeedstockHeaders,
    } = useLithium()
    const pluralType = {
      plant: 'plants',
      company: 'companies',
      country: 'countries',
      region: 'regions',
      product: 'products',
      status: 'status',
    }
    const overview = ref()
    const headers = ref()
    const breadcrumb = ref({ prevItems: [] })
    const factbox = ref()
    const feedstock = ref<{
      company: any
      region: any
      plant: any
      country: any
      product: any
      status: any
      factbox: FactBoxItemProps[]
      breadcrumb: any
    }>({
      company: null,
      region: null,
      plant: null,
      country: null,
      product: null,
      status: null,
      factbox: null,
      breadcrumb: null,
    })

    const title = computed(() => headers.value?.header?.title)

    const clearState = () => {
      overview.value = null
      headers.value = null
      factbox.value = null
      feedstock.value = {
        company: null,
        region: null,
        plant: null,
        country: null,
        product: null,
        status: null,
        factbox: null,
        breadcrumb: null,
      }
    }

    const getTopProducers = async (path: string) => {
      const oldData = overview.value
      if (oldData) return
      overview.value = await getChemicalFeedstockProducers(path, weightedUnweighted.value)
    }

    const getFeedstockDetailData = async ({ id, type }) => {
      const response = await getChemicalFeedstockDetail({
        id,
        type,
        valueType: weightedUnweighted.value,
      })
      const responseType = pluralType[type]
      const data = response.data[responseType]
      const sourceData = response.sourceData

      if (type === 'status') {
        feedstock.value[type] = { data, sourceData }
        feedstock.value[type].data = formatChemicalStatus(response.data.status)
        feedstock.value[type].year = response.data.year
        return
      }

      const { unit, weighted } = response.data
      feedstock.value[type] = { data, unit, weighted, sourceData }
    }

    const getFeedstockHeadersData = async (id) => {
      const response = await getChemicalFeedstockHeaders(id, weightedUnweighted.value)
      headers.value = response
      feedstock.value.factbox = getFormattedChemicalProductFactbox(response.facts)
      feedstock.value.breadcrumb = {
        prevItems: ['Lithium', 'Supply', 'Chemical', 'Feedstock', response.header.title],
      }
    }

    return {
      title,
      feedstock,
      factbox,
      breadcrumb,
      overview,
      getTopProducers,
      getFeedstockDetailData,
      getFeedstockHeadersData,
      clearState,
    }
  },
)
