import {
  FactBoxItemTypes,
  type FactBoxItemProps,
} from '~/components/reports/FactBox/v2/definitions'
import {
  FactboxTexts,
  extendSupplyFactboxesCommonSettings,
  supplyFactboxesCommonSettings,
} from '~/constants/general'
import { formatObjectValueFactbox, handleArray } from '~/utils/formatFactboxData'

export const getFormattedChemicalPlantFactbox = (facts): FactBoxItemProps[] => [
  {
    ...supplyFactboxesCommonSettings,
    label: FactboxTexts.status.label,
    value: facts.statusWithLaunchYear,
    dataTestid: FactboxTexts.status.dataTestAttribute,
  },
  {
    ...supplyFactboxesCommonSettings,
    label: FactboxTexts.feedStock.label,
    value: handleArray(facts.feedStock),
    dataTestid: FactboxTexts.feedStock.dataTestAttribute,
  },
  {
    ...supplyFactboxesCommonSettings,
    label: FactboxTexts.ownership.label,
    value: facts.ownership,
    dataTestid: FactboxTexts.ownership.dataTestAttribute,
  },
  formatObjectValueFactbox(facts.currentProduction),
  {
    ...supplyFactboxesCommonSettings,
    label: FactboxTexts.operator.label,
    value: facts.operator,
    dataTestid: FactboxTexts.operator.dataTestAttribute,
  },
  {
    ...supplyFactboxesCommonSettings,
    label: FactboxTexts.product.label,
    value: facts.product,
    dataTestid: FactboxTexts.product.dataTestAttribute,
  },
]

export const getFormattedChemicalCompanyFactbox = (facts): FactBoxItemProps[] => {
  const factboxes = [
    {
      ...supplyFactboxesCommonSettings,
      label: FactboxTexts.marketPosition.label,
      value: facts.status,
      dataTestid: FactboxTexts.marketPosition.dataTestAttribute,
    },
    {
      ...supplyFactboxesCommonSettings,
      label: FactboxTexts.feedStock.label,
      value: handleArray(facts.feedStock),
      dataTestid: FactboxTexts.feedStock.dataTestAttribute,
    },
    {
      ...extendSupplyFactboxesCommonSettings('chemical-company-products-list'),
      label: FactboxTexts.products.label,
      value: facts.products,
      variant: FactBoxItemTypes.LIST,
      dataTestid: FactboxTexts.products.dataTestAttribute,
    },
    {
      ...supplyFactboxesCommonSettings,
      label: FactboxTexts.companyHQ.label,
      value: facts.companyHQ,
      dataTestid: FactboxTexts.companyHQ.dataTestAttribute,
    },
    formatObjectValueFactbox(facts.currentProduction),
  ]

  if (facts.esgTier) {
    factboxes.push({
      ...supplyFactboxesCommonSettings,
      label: FactboxTexts.benchmarkSustainabilityIndex.label,
      value: facts.esgTier,
      dataTestid: FactboxTexts.benchmarkSustainabilityIndex.dataTestAttribute,
    })
  }

  return factboxes
}

export const getFormattedChemicalCountryFactbox = (facts): FactBoxItemProps[] => [
  {
    ...supplyFactboxesCommonSettings,
    label: FactboxTexts.feedStock.label,
    value: handleArray(facts.feedStock),
    dataTestid: FactboxTexts.feedStock.dataTestAttribute,
  },
  {
    ...supplyFactboxesCommonSettings,
    label: FactboxTexts.operatingPlants.label,
    value: `${facts.operatingPlants}`,
    dataTestid: FactboxTexts.operatingPlants.dataTestAttribute,
  },
  {
    ...extendSupplyFactboxesCommonSettings('chemical-country-products-list'),
    label: FactboxTexts.products.label,
    value: facts.products,
    variant: FactBoxItemTypes.LIST,
    dataTestid: FactboxTexts.products.dataTestAttribute,
  },
  {
    ...supplyFactboxesCommonSettings,
    label: FactboxTexts.plannedProjects.label,
    value: `${facts.plannedProjects}`,
    dataTestid: FactboxTexts.plannedProjects.dataTestAttribute,
  },
  formatObjectValueFactbox(facts.currentProduction),
]

export const getFormattedChemicalProductFactbox = (facts): FactBoxItemProps[] => [
  {
    ...supplyFactboxesCommonSettings,
    label: FactboxTexts.regionsProduction.label,
    value: handleArray(facts.regionsProduction),
    dataTestid: FactboxTexts.regionsProduction.dataTestAttribute,
  },
  formatObjectValueFactbox(facts.currentProduction),
  {
    ...supplyFactboxesCommonSettings,
    label: FactboxTexts.operatingPlants.label,
    value: facts.operatingPlants.toString(),
    dataTestid: FactboxTexts.operatingPlants.dataTestAttribute,
  },
  {
    ...supplyFactboxesCommonSettings,
    label: FactboxTexts.plannedProjects.label,
    value: facts.plannedProjects.toString(),
    dataTestid: FactboxTexts.plannedProjects.dataTestAttribute,
  },
]

export const getFormattedChemicalRegionFactbox = (facts): FactBoxItemProps[] => [
  {
    ...supplyFactboxesCommonSettings,
    label: FactboxTexts.feedStock.label,
    value: handleArray(facts.feedStock),
    dataTestid: FactboxTexts.feedStock.dataTestAttribute,
  },
  {
    ...supplyFactboxesCommonSettings,
    label: FactboxTexts.operatingPlants.label,
    value: facts.operatingPlants.toString(),
    dataTestid: FactboxTexts.operatingPlants.dataTestAttribute,
  },
  {
    ...extendSupplyFactboxesCommonSettings('chemical-region-products-list'),
    label: FactboxTexts.products.label,
    value: facts.products,
    variant: FactBoxItemTypes.LIST,
    dataTestid: FactboxTexts.products.dataTestAttribute,
  },
  {
    ...supplyFactboxesCommonSettings,
    label: FactboxTexts.plannedProjects.label,
    value: facts.plannedProjects.toString(),
    dataTestid: FactboxTexts.plannedProjects.dataTestAttribute,
  },
  formatObjectValueFactbox(facts.currentProduction),
]
