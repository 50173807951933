import { defineStore, storeToRefs } from 'pinia'
import { computed, ref } from 'vue'
import type { FactBoxItemProps } from '~/components/reports/FactBox/v2/definitions'
import useLithium from '~/composables/http/useLithium'
import { useGeneralStore } from '~/stores/general'
import { getFormattedChemicalProductFactbox } from '~/utils/factbox/lithium/supply/chemical'

export const useLithiumSupplyChemicalProductStore = defineStore(
  'lithium-supply-chemical-product',
  () => {
    const generalStore = useGeneralStore()
    const { weightedUnweighted } = storeToRefs(generalStore)

    const { getChemicalProductProducers, getChemicalProductDetail, getChemicalProductHeaders } =
      useLithium()
    const pluralType = {
      plant: 'plants',
      company: 'companies',
      country: 'countries',
      region: 'regions',
      feedstock: 'feedstocks',
      status: 'status',
    }
    const overview = ref()
    const home = ref()
    const headers = ref()
    const breadcrumb = ref({ prevItems: [] })
    const factbox = ref()
    const product = ref<{
      company: any
      region: any
      plant: any
      country: any
      feedstock: any
      status: any
      factbox: FactBoxItemProps[]
      breadcrumb: any
    }>({
      company: null,
      region: null,
      plant: null,
      country: null,
      feedstock: null,
      status: null,
      factbox: null,
      breadcrumb: null,
    })

    const title = computed(() => headers.value?.header?.title)

    const clearState = () => {
      overview.value = null
      home.value = null
      headers.value = null
      factbox.value = null
      product.value = {
        company: null,
        region: null,
        plant: null,
        country: null,
        feedstock: null,
        status: null,
        factbox: null,
        breadcrumb: null,
      }
    }

    const getTopProducers = async (path: string) => {
      const oldData = overview.value
      if (oldData) return

      overview.value = await getChemicalProductProducers({
        path,
        valueType: weightedUnweighted.value,
      })
    }

    const fetchHomeProducers = async (limit?: number) => {
      const oldData = home.value

      if (oldData) return

      home.value = await getChemicalProductProducers({ limit, valueType: weightedUnweighted.value })
    }

    const getProductDetailData = async ({ id, type }) => {
      const response = await getChemicalProductDetail({
        id,
        type,
        valueType: weightedUnweighted.value,
      })
      const responseType = pluralType[type]
      const sourceData = response.sourceData
      const data = response.data[responseType]

      if (type === 'status') {
        product.value[type] = { data, sourceData }
        product.value[type].data = formatChemicalStatus(response.data.status)
        product.value[type].year = response.data.year
        return
      }

      const { unit, weighted } = response.data
      product.value[type] = { data, unit, weighted, sourceData }
    }

    const getProductHeadersData = async (id: string) => {
      const {
        data: { data: response },
      } = await getChemicalProductHeaders(id, weightedUnweighted.value)
      headers.value = response
      product.value.factbox = getFormattedChemicalProductFactbox(response.facts)
      product.value.breadcrumb = {
        prevItems: ['Lithium', 'Supply', 'Chemical', 'Product', response.header.title],
      }
    }

    return {
      title,
      product,
      factbox,
      breadcrumb,
      overview,
      home,
      getTopProducers,
      getProductDetailData,
      getProductHeadersData,
      fetchHomeProducers,
      clearState,
    }
  },
)
